<template>
  <div class="wrap">
    <div class="header">
      <div class="title"><img
			src="../assets/img/return_index.png"
			class="return1"
			alt=""
			@click="$router.back()"
        	/>{{$t('main.detailappre')}}</div>
      <div class="right-icon">
        <img src="../assets/img/logo-icon.png" alt="">
      </div>
    </div>
    
    <!-- 每一块区域 -->
    <div class="container">
      <div class="box" v-for="(item, index) in detail_info" :key="index">
        <img class="smallImg" src="../assets/img/ghosting.png" alt="" />
        <div class="box-title">{{$t('main.apprePic')}} {{ index + 1 }}</div>
        <div class="text">{{ item.content }}</div>
        <div class="add-img">
          <img class="bigImg" @click="showSmallImage(index)" :src="item.pic+'?width=350'" alt="">
        </div>

        <div class="footer">
          <img @click.stop="magnify(index)" class="footerImg" src="../assets/img/button.png" alt="" />
        </div>
      </div>
    </div>
    <privacy ref="privacy"></privacy>
  </div>
</template>

<script>
import { ImagePreview  } from "vant";
import privacy from "../components/privacy.vue"
export default {
  components:{
    privacy
  },
  data() {
    return {
      bigitem: false,
      detail_info: [],
      current_hpic: '',
      id:'',
      collector_id:"",
      userId:""
    }
  },
  async mounted() {
    if(this.$route.query.__params){
     let json = JSON.parse(this.$base64.decode(this.$route.query.__params))
     
     let query = json
     this.$route.params.id = query.id
    }
    let token = localStorage.getItem('user_token')
    if(this.$route.query.code && !token){
      let querys = ''
      if(this.$route.query.phone){
        let phone =this.getQueryString('phone')
        let nickName = this.getQueryString('phone_code')
        querys = `&phone=${phone}&phone_code=${decodeURIComponent(nickName)}`
      }
        await this.axios.get('/auth/nt?code='+this.$route.query.code+querys).then((res)=>{
          if(res.data.code == -1 && res.data.msg == '请填写手机号'){
            this.$refs.privacy.openPopup()
            return
          }
          window.localStorage.setItem('user_token',res.data.data.token)
          token = localStorage.getItem('user_token')
        }).catch(() => {})
    }
    if(token){
      this.axios.get('/auth/me').then((res)=>{
        this.collector_id = res.data.data.user.id
      })
    }
    const id = this.$route.params.id
    let newdata = ''
    await this.axios.post('common/encrypt',{goods_id:id}).then((res)=>{
      newdata = res.data.data.data_encrypted
    })
    this.axios.get(`/goods/info?data=${newdata}`).then((res) => {
      // console.log(res)
      this.id = res.data.data.collector ? res.data.data.collector.user_id : 0
      this.userId = res.data.data.collector ? 0 : res.data.data.user_id
      if(res.data.data.detail_status == 2){
        // weui.alert("防伪图片存在违规行为，请重新上传")
        return
      }
      if(this.$i18n.locale == 'en'){
        if(res.data.data.detail_info_intl){
          res.data.data.detail_info = res.data.data.detail_info_intl
        }
      }
      if(typeof res.data.data.detail_info == 'string'){
        this.detail_info = JSON.parse(res.data.data.detail_info)
        return
      }
      this.detail_info = res.data.data.detail_info
    })
  },
  methods: {
    getQueryString(name) {
			var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
			var r = window.location.search.substr(1).match(reg);
			if (r != null) return decodeURIComponent(r[2]);
			return null;
		},
    magnify(index) {
      let token = localStorage.getItem('user_token')
      if(!token){
        this.$refs.privacy.showFlag()
        return
      }
      if(this.id == this.collector_id || this.userId == this.collector_id){
        ImagePreview({ images: [this.detail_info[index].hpic], showIndex: false,closeable: true });
      }else{
        weui.alert(this.$t('main.holdercanwatch'),{buttons:[{label:this.$t('main.confIrm')}]})
      }
     
    },
    showSmallImage(index){
      ImagePreview({ images: [this.detail_info[index].pic], showIndex: false,closeable: true });
    }
  },
};
</script>

<style scoped>

.header {
  display: flex;
    justify-content: space-between;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
    margin-top: 0.01rem;
    padding: 0.05rem 0.15rem;
    align-items: center;
    background: #fff;
}

.title {
  font-size: 0.18rem;
  color: rgb(38,111,43);
}


.right-icon {
  width: 1rem;
    height: 0.39rem;
}

.add-img {
  width: 100%;
  height: 3.17rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border: 1px solid #eee;
  border-radius: 0.1rem;
  margin: 0.05rem 0;
  overflow: hidden;
}

.right-icon img {
  display: block;
  width: 100%;
}

/* 放大图 */
#bigImage {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .5);
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

#bigImage img {
  width: 3rem;
}

/* 每一块区域 */
.container {
  background-color: rgb(235, 230, 224);
  padding: 0.05rem 0 0.1rem;
  border-radius: 0.05rem;
  min-height: calc(100vh - 0.6rem);
}

.box {
  border: 0.01remediumvioletredid #edebe7;
  width: 84%;
  margin: 0.1rem 0 0 0.15rem;
  border-radius: 0.1rem;
  background-color: #fff;
  padding: 0.15rem;
  box-shadow: 0px 0px 0.06rem 0.03rem #ccc;
}

.box-title {
  font-weight: 600;
  font-size: 0.14rem;
  margin-bottom: 0.1rem;
}

.box .smallImg {
  width: 0.18rem;
  margin-right: 0.1rem;
  margin-top: 0.03rem;
  float: left;
}

.box .bigImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.box .footer {
  display: flex;
  justify-content: center;
}

.box .footer .footerImg {
  width: 1.4rem;
}
</style>
